import { useSelector } from 'react-redux';
import { RootState } from 'app/store/store';

export enum POSTHOG_EVENTS {
  UserStartedKeywordSpySearch = 'UserStartedKeywordSpySearch',
  UserFinishedKeywordSpySearch = 'UserFinishedKeywordSpySearch',
  UserStartedASINsSearch = 'UserStartedASINsSearch',
  UserFinishedASINsSearch = 'UserFinishedASINsSearch',
  UserStartedLoadingProjectDetailsByASINs = 'UserStartedLoadingProjectDetailsByASINs',
  UserFinishedLoadingProjectDetailsByASINs = 'UserFinishedLoadingProjectDetailsByASINs',
  UserStartedLoadingProjectDetailsByKeywords = 'UserStartedLoadingProjectDetailsByKeywords',
  UserFinishedLoadingProjectDetailsByKeywords = 'UserFinishedLoadingProjectDetailsByKeywords',
  UserStartedLoadingProjectDetailsByRanks = 'UserStartedLoadingProjectDetailsByRanks',
  UserFinishedLoadingProjectDetailsByRanks = 'UserFinishedLoadingProjectDetailsByRanks',
  UserStartedLoadingProjectDetailsByTrend = 'UserStartedLoadingProjectDetailsByTrend',
  UserFinishedLoadingProjectDetailsByTrend = 'UserFinishedLoadingProjectDetailsByTrend',
}

export const useIsNoteFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state?.auth?.userDetails?.featureFlags?.['notes-feature'] ?? false,
  );
};

export const useIsSwitchAccountButtonFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['switch-account-button'] ?? false,
  );
};

export const useIsRankKeywordTabFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['rank-by-keyword-tab-feature'] ??
      false,
  );
};

export const useIsRankBSRTabFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['rank-bsr-tab-feature'] ?? false,
  );
};

export const useIsRankCurrentTabFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['current-rank-tab-feature'] ??
      false,
  );
};

export const useIsRankDataByIntervalRangeEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['rank-data-by-interval-range'] ??
      false,
  );
};

export const useIsTeamMenuFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['team-menu-feature'] ?? false,
  );
};

export const useIsSuggestKeywordFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['suggest-keyword-feature'] ??
      false,
  );
};

export const useIsSuggestASINFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['suggest-asin-feature'] ?? false,
  );
};

export const useIsSettingConnectionMenuFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['setting-connection-menu'] ??
      false,
  );
};

export const useIsGoogleSheetButtonFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['google-sheet-button'] ?? false,
  );
};

export const useIsAccessAllMarketplaceFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['access-all-marketplace'] ??
      false,
  );
};

export const useIsASINWidgetsFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['asin-widget-feature'] ?? false,
  );
};

export const useIsProjectMarketplacesFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['project-marketplaces-feature'] ??
      false,
  );
};

export const useIsAsinInsightsAdditionalOptionsFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.[
        'asin-insights-additional-options'
      ] ?? false,
  );
};    

export const useIsConnectAmazonAccountFeatureEnabled = () => {
  return useSelector(
    (state: RootState) =>
      state.auth?.userDetails?.featureFlags?.['connect-amazon-account'] ??
      false,
  );
};
