import _isFunction from "lodash/isFunction";
import moment from "moment";
import { call, put, select, takeLatest } from "redux-saga/effects";

import { toast } from 'react-toastify';
import { getJwt } from '../../../crud/auth.crud';
import { getHeaders, postRequestWrapper, request, serialize } from "../../../utils/fetch";
import { actions as frontendAnalyticsActions } from '../frontend-analytics/actions';
import { actionTypes } from './actionTypes';
import { actions } from './actions';
import { searchHistoryASINsAdapter } from './adapter';
import queryString from 'query-string';

export function* watchAsinKeywordsSaga() {
    yield takeLatest(actionTypes.FetchSearchTerms, apiFetchSearchTermsSaga);
    yield takeLatest(actionTypes.FetchTopAsinRank, apiFetchTopAsinRankSaga);
    yield takeLatest(actionTypes.FetchKeywordSales, apiFetchKeywordSalesSaga);
    yield takeLatest(actionTypes.FetchAsinKeywordDetails, apiFetchAsinKeywordDetailsSaga);
    yield takeLatest(actionTypes.SearchHistoryASINsRequest, apiFetchSearchHistoryASINsSaga);
    yield takeLatest(actionTypes.SearchHistoryASINsDeleteRequest, apiFetchSearchHistoryASINsDeleteSaga);
}

export const selectUserSelected = (state) => state.auth.user;

function* apiFetchSearchTermsSaga(action) {
    yield put(actions.disableSendButtonState());
    yield put(actions.setIsValidAsin(true));
    const requestPayload = {
      ASIN: action.payload.ASIN,
      startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
      endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
      exactAsinOnly: action.payload.exactAsin,
      rangeType: action.payload.rangeType.toLowerCase(),
      marketplace: action.payload.marketplace,
      useNew: action.payload.useNew
    };
    const headers = yield getHeaders();

    const userSelected = yield select(selectUserSelected);
    const action_id = Math.floor(Math.random() * (99999999999999 - 10**12 + 1) + 10**12);
    const dateRange = {
      startDate: requestPayload.startDate,
      endDate: requestPayload.endDate,
    }

    const dataLogging = {
      asin: requestPayload.ASIN,
      user_id: userSelected.sub,
      email: userSelected.email,
      dateRange: JSON.stringify(dateRange),
      type: "asin-insights-search",
      page: "asin-insights",
      action: "asin-insights-search",
      action_id: action_id,
    }
    yield put(frontendAnalyticsActions.addFrontendAnalytics(dataLogging));
    const apiRes = yield call(() => request(headers).get(`/asin-records?${serialize(requestPayload)}`));
    // let apiRes = yield fetch('./mock-asin.json');
    // apiRes = yield apiRes.json();
    if (apiRes) {
        if (apiRes.status === 'FAILED') {
            toast.error(apiRes.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const dataLoggingUpdate = {
              action_id: action_id,
            }
            yield put(frontendAnalyticsActions.updateFrontendAnalytics(dataLoggingUpdate));
            yield put(
                actions.UpdateResultsAsinKeywords(apiRes)
            );
        }
    } else {
      yield put(actions.rowClicked(null))
    }
    yield put(actions.disableSendButtonState(false));
}

function* apiFetchTopAsinRankSaga(action) {
    const token = yield getJwt();
    const requestPayload = {
      data: action.payload.data
    };
    const apiRes = yield call(postRequestWrapper, "/asin-records/top-asin-rank", token, requestPayload);
    if (apiRes && apiRes.data) {
      const res = {
        page: action.payload.page,
        data: apiRes.data
      }
      yield put(
        actions.updateTopAsinRank(res)
      );
    }
}

function* apiFetchKeywordSalesSaga(action) {
    yield put(actions.setFetchingRecordState(true));
    const requestPayload = {
      keyword: action.payload.keyword,
      startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
      endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
      rangeType: action.payload.rangeType.toLowerCase(),
      marketplace: action.payload.marketplace,
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(`/asin-records/record-keyword-sales?${serialize(requestPayload)}`));
    if (apiRes && apiRes.data) {
      const res = {
        keyword: action.payload.keyword,
        data: apiRes.data
      }
      yield put(actions.updateKeywordSales(res));
    } else {
      yield put(actions.rowClicked(null))
    }
    yield put(actions.setFetchingRecordState(false));
}

function* apiFetchAsinKeywordDetailsSaga(action) {
    yield put(actions.setFetchingRecordState(true));
    const requestPayload = {
      keyword: action.payload.keyword,
      startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
      endDate: moment(action.payload.endDate).format("YYYY-MM-DD"),
      rangeType: action.payload.rangeType.toLowerCase(),
      ASIN: action.payload.ASIN,
      topAsin: action.payload.topAsin,
      exactAsinOnly: action.payload.exactAsin,
      marketplace: action?.payload?.marketplace || ''
    };
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(`/records/record?${serialize(requestPayload)}`));
    if (apiRes && apiRes.data) {
      const res = {
        keyword: action.payload.keyword,
        tableData: apiRes.data.tableData,
        chartData: {
          arrDates: apiRes.data.arr_dates,
          arrSales: apiRes.data.arr_sales,
          arrClicks: apiRes.data.arr_clicks,
          arrTop3Dates: apiRes.data.arr_top3_dates,
          arrAsinTop3: apiRes.data.arr_asin_top3,
          arrConversions: apiRes.data.arr_conversions,
        },
        top3TableData: apiRes.data.top3TableData,
        arrAsins: apiRes.data.arASINs
      }
      yield put(actions.updateAsinKeywordDetails(res));
    } else {
      yield put(actions.rowClicked(null))
    }
    yield put(actions.setFetchingRecordState(false));
}

function* apiFetchSearchHistoryASINsSaga(action) {
    const headers = yield getHeaders();
    const apiRes = yield call(() => request(headers).get(`/history?area=ASINKeywords`));

    if (apiRes.status === 'OK') {
        const adaptedPayload = searchHistoryASINsAdapter(apiRes.data);
        yield put(actions.searchHistoryASINsSuccess(adaptedPayload));
    } else {
        yield put(actions.searchHistoryASINsFailure());
    }
}

function* apiFetchSearchHistoryASINsDeleteSaga(action) {
    const headers = yield getHeaders();
    const id = action.payload;
    const stringifiedString = queryString.stringify({id});
    const apiRes = yield call(() => request(headers).delete(`/history?${stringifiedString}`));
    if (apiRes.status === 'OK') {
        if (_isFunction(action.cb)) {
            action.cb();
        }
        yield put(actions.searchHistoryASINsDeleteSuccess(action.payload));
    } else {
        yield put(actions.searchHistoryASINsDeleteFailure());
    }
}
