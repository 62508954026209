import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField, Checkbox } from '@material-ui/core';
import { actions } from '../../../store/ducks/auth/actions';
import { actions as additionalUserActions } from '../../../store/ducks/additional-users/actions';
import { Auth } from 'aws-amplify';
import styles from './Registration.module.scss';
import {
  passwordHelper,
  registrationCompleteText,
} from '../../../../constants';
import DialogNotifyWindow from 'app/components/DialogNotifyWindow/DialogNotifyWindow';
import { sentry } from '../../../utils/sentry';
import { v4 as uuidv4 } from 'uuid';
import { AuthWrapper } from 'app/Wrappers/AuthWrapper.js';
import successIcon from 'app/assets/images/icons/successIcon.jpg';
import errorIcon from 'app/assets/images/icons/errorIcon.jpg';
import removeRedEyeIcon from 'app/assets/images/icons/remove_red_eye2.jpg';

const Registration = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const errorset = useSelector(state => state.auth.signUpError);
  const loading = useSelector(state => state.auth.loading);
  const { signUpError, enableLoading, disableLoading } = actions;
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlTokenParam = searchParams.get('token');

  useEffect(() => {
    clearErrorPanel();
    return () => {
      clearErrorPanel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTimeout(500, setOpen(false));
    history.push('/auth/login/');
  };

  const clearErrorPanel = () => {
    dispatch(signUpError(''));
  };

  async function register(values) {
    clearErrorPanel();
    const {
      email,
      password,
      lastName: family_name,
      firstName: given_name,
    } = values;

    let userDoesNotExist = false;
    try {
      const user = await Auth.signIn(email, '0');
      if (user) {
        await Auth.signOut();
        dispatch(signUpError('This email is already taken'));
      }
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        userDoesNotExist = true;
      }
    }

    if (!userDoesNotExist) {
      return dispatch(signUpError('This email is already taken'));
    }

    try {
      const user = await Auth.signUp({
        username: uuidv4(),
        password,
        attributes: {
          email: email,
          family_name,
          given_name,
        },
      });
      dispatch(additionalUserActions.updateUserId({email: values.email, userId: user.userSub}));
    } catch (err) {
      if (err.__type === 'InternalErrorException') {
        sentry.captureException(err, {
          username: email,
          attributes: {
            email: email,
          },
        });
        dispatch(
          signUpError(
            'There was an error while creating your account, please try again later',
          ),
        );
      } else {
        dispatch(signUpError(err.message));
      }
    }
  }

  return (
    <AuthWrapper>
      <div className="login-form-title">Sign up</div>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <Formik
            initialValues={{
              email: urlTokenParam ? atob(urlTokenParam) : '',
              firstName: '',
              lastName: '',
              password: '',
              acceptTerms: false,
              confirmPassword: '',
            }}
            validate={values => {
              const errors = {};

              if (
                values.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              if (
                values.password &&
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{8,}$/i.test(
                  values.password,
                )
              ) {
                errors.password = 'Password does not meet criteria';
              }

              if (!values.acceptTerms) {
                errors.acceptTerms = 'Accept Terms';
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              dispatch(enableLoading());
              setTimeout(() => {
                register(values)
                  .then(() => {
                    dispatch(disableLoading());
                    handleClickOpen();
                  })
                  .catch(() => {
                    dispatch(disableLoading());
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: 'AUTH.VALIDATION.INVALID_LOGIN',
                      }),
                    );
                  });
              }, 800);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="kt-form"
              >
                {errorset ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{errorset}</div>
                  </div>
                ) : (
                  status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )
                )}
                <div className="form-group">
                  <div className="label">First Name</div>
                  <TextField
                    margin="normal"
                    className="kt-width-full custom-form-label"
                    name="firstName"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    helperText={touched.firstName && errors.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    placeholder="First name"
                  />
                  {Boolean(touched.firstName && errors.firstName) ? (
                    <img className="error-icon" src={errorIcon} alt="icon" />
                  ) : (
                    Boolean(
                      touched.firstName &&
                        values.firstName &&
                        !errors.firstName,
                    ) && (
                      <img
                        className="success-icon"
                        src={successIcon}
                        alt="icon"
                      />
                    )
                  )}
                </div>
                <div className="form-group mb-3">
                  <div className="label">Last Name</div>
                  <TextField
                    margin="normal"
                    className="kt-width-full custom-form-label"
                    name="lastName"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    helperText={touched.lastName && errors.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    placeholder="Last name"
                  />
                  {Boolean(touched.lastName && errors.lastName) ? (
                    <img className="error-icon" src={errorIcon} alt="icon" />
                  ) : (
                    Boolean(
                      touched.lastName && values.lastName && !errors.lastName,
                    ) && (
                      <img
                        className="success-icon"
                        src={successIcon}
                        alt="icon"
                      />
                    )
                  )}
                </div>
                <div className="form-group mb-3">
                  <div className="label">Email</div>
                  <TextField
                    disabled={!!urlTokenParam}
                    autoComplete={urlTokenParam ? 'off' : 'on'}
                    margin="normal"
                    className="kt-width-full custom-form-label"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    placeholder="Your email"
                  />
                  {Boolean(touched.email && errors.email) ? (
                    <img className="error-icon" src={errorIcon} alt="icon" />
                  ) : (
                    Boolean(touched.email && values.email && !errors.email) && (
                      <img
                        className="success-icon"
                        src={successIcon}
                        alt="icon"
                      />
                    )
                  )}
                </div>
                <div className="form-group mb-3">
                  <div className="label">Password</div>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    margin="normal"
                    className="kt-width-full custom-form-label"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    placeholder="Your password"
                  />
                  {Boolean(touched.password && errors.password) ? (
                    <img
                      className="error-icon error-icon-password"
                      src={errorIcon}
                      alt="icon"
                    />
                  ) : (
                    Boolean(
                      touched.password && values.password && !errors.password,
                    ) && (
                      <img
                        className="success-icon success-icon-password"
                        src={successIcon}
                        alt="icon"
                      />
                    )
                  )}
                  <img
                    onClick={() => setShowPassword(prev => !prev)}
                    className="hide-password-icon"
                    src={removeRedEyeIcon}
                    alt="hide"
                  />
                </div>
                <div className={styles.passwordHelper}>{passwordHelper}</div>
                <div className={styles.loginPolicy}>
                  <Checkbox
                    className={styles.loginCheckbox}
                    color="primary"
                    name="acceptTerms"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.acceptTerms}
                  />
                  <div>
                    <span>I agree with the </span>
                    <a
                      href="https://datarova.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>
                    <span> and </span>
                    <a
                      href="https://datarova.com/terms-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms of conditions,
                    </a>
                  </div>
                </div>
                <div className="login-form-actions">
                  <button
                    type="submit"
                    className={`btn button-login ${clsx({
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                        loading || false,
                    })}`}
                    disabled={
                      !values.acceptTerms ||
                      !values.firstName ||
                      !values.lastName ||
                      !values.email ||
                      !values.password ||
                      loading ||
                      errors.email ||
                      errors.password
                    }
                  >
                    Get Started
                  </button>
                  <div className={styles.signInButton}>
                    <FormattedMessage id="AUTH.GENERAL.HAVE_ACCOUNT" />
                    <Link
                      to="/auth"
                      className="kt-link kt-login__link-register"
                    >
                      <FormattedMessage id="AUTH.LOGIN.TITLE" style={{}} />
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        {errorset || loading ? (
          ''
        ) : (
          <DialogNotifyWindow
            text={registrationCompleteText}
            open={open}
            handleClose={handleClose}
          />
        )}
      </div>
    </AuthWrapper>
  );
};

export default Registration;
