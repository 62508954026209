import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  progressBarTextContainer: {
    minWidth: 35,
  },
  progressBarText: {
    fontWeight: theme.primaryTitleFontWeight,
    fontSize: '16px',
    color: theme.primaryTextColor,
    fontFamily: theme.primaryFontFamily,
    lineHeight: '24px',
    marginTop: '24px',
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  linearProgressPart: {
    width: '100%',
    mr: 1,
    border: '1px solid #333333',
    height: '12px',
    borderRadius: '6px',
    padding: '1px !important',
    marginTop: '16px',
  },
  progressBar: {
    height: '8px',
    borderRadius: '6px !important',
    backgroundColor: '#ffffff',
    padding: 1,
    zIndex: 1, // to prevent left side glitching on safari
    '& .MuiLinearProgress-bar': {
      backgroundColor: (props) => (props.color ? props.color : '#797FED'),
      borderRadius: '4px !important',
      zIndex: 0, // to prevent left side glitching on safari
    },
  },
}));
