import React from 'react';

import { LinearProgress } from 'app/components/Material/LinearProgress';
import { Typography } from 'app/components/Material/Typography';
import { Box } from 'app/components/Material/Box';
import { useStyles } from './styles';

export const LinearProgressWithLabel = props => {
  const classes = useStyles({ color: props.color });

  const { value, hideIndicator } = props;

  return (
    <>
      {!hideIndicator && (
        <Box className={classes.progressBarTextContainer}>
          <Typography
            className={classes.progressBarText}
            variant="body2"
            color="textPrimary"
          >
            {`Fetching data... ${Math.round(value)}%`}
          </Typography>
        </Box>
      )}
      <Box>
        <Box className={classes.linearProgressPart}>
          <LinearProgress
            className={classes.progressBar}
            variant="determinate"
            value={value}
          />
        </Box>
      </Box>
    </>
  );
};
