import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import objectPath from 'object-path';
// import Header from "./header/Header";
import SubHeader from './sub-header/SubHeader';
import HeaderMobile from './header/HeaderMobile';
import AsideLeft from './aside/AsideLeft';
// import Footer from "./footer/Footer";
import StickyToolbar from '../../app/partials/layout/StickyToolbar';
import HTMLClassService from './HTMLClassService';
import LayoutConfig from './LayoutConfig';
import MenuConfig from './MenuConfig';
import LayoutInitializer from './LayoutInitializer';
import KtContent from './KtContent';
import './assets/Base.scss';
import { removeCSSClass } from '_metronic/utils/utils';
import clsx from 'clsx';

const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
  contentContainerClasses,
}) {
  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  // window.scrollTo(0, 0);

  const location = useLocation();

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const contentCssClasses = htmlClassService.classes.content.join(' ');
  const user = useSelector((state) => state.auth.user);
  // const parentEmail = useSelector(state => state.auth.parentEmail);
  const isAuthorized = user != null && user.sub != null;

  useEffect(() => {
    if (location?.pathname && location?.pathname.includes('/settings')) {
      setIsSettingsOpen(true);
      removeCSSClass(document.body, 'kt-aside--minimize');
      document.body.classList.add('kt-aside--minimize-hover');
    }
  }, [location]);

  if (!isAuthorized) {
    return children;
  }

  const menuConfig = MenuConfig;

  return selfLayout !== 'blank' ? (
    <LayoutInitializer
      menuConfig={menuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}
      {/* {parentEmail ? <div style={{position: 'absolute', top: 0, background: 'green', width: '100%', color: '#fff', textAlign: 'center'}}>
        You are logged in as a team member of {parentEmail}
      </div> : null} */}
      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {/* <!-- begin:: Aside Left --> */}
          {asideDisplay && (
            <>
              <AsideLeft
                isSettingsOpen={isSettingsOpen}
                setIsSettingsOpen={setIsSettingsOpen}
              />
            </>
          )}
          {/* <!-- end:: Aside Left --> */}
          <div
            className={clsx(
              'kt-grid__item',
              'kt-grid__item--fluid',
              'kt-grid kt-grid--hor',
              'kt-wrapper',
              {
                'kt-aside__wrapper-fixed': isSettingsOpen,
              },
            )}
            id="kt_wrapper"
            style={{ marginBottom: 50, paddingTop: 25, background: '#fff' }}
          >
            {/* <!-- begin:: Header READY --> */}

            {/* <Header /> */}
            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Content --> */}
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
              style={{
                paddingTop: 0,
              }}
            >
              {/* <!-- begin:: Content Head --> */}
              {subheaderDisplay && <SubHeader />}
              {/* <!-- end:: Content Head --> */}

              {/* <!-- begin:: Content Body --> */}
              {/* TODO: add class to animate  kt-grid--animateContent-finished */}
              <KtContent>{children}</KtContent>
              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
            {/* <Footer /> */}
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      {/* <QuickPanel /> */}
      {/* <ScrollTop /> */}
      <StickyToolbar />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, 'self.layout'),
  asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  subheaderDisplay: objectPath.get(layoutConfig, 'subheader.display'),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    'header.self.fixed.desktop',
  ),
  contentContainerClasses: '',
  // contentContainerClasses: builder.selectors.getClasses(store, {
  //   path: "content_container",
  //   toString: true
  // })
});

export default connect(mapStateToProps)(Layout);
