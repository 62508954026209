import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const setupSentry = () => {
  // Only install in production environments
  const installSentry =
    process.env.NODE_ENV === 'production' &&
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== 'test.datarova.com' &&
    window.location.protocol !== 'file:';

  return {
    init: () => {
      if (installSentry) {
        Sentry.init({
          dsn: 'https://434f3b2bace842b088514ce86c7d073c@o374641.ingest.sentry.io/4504454959071232',
          environment: window.location.hostname,
          // Increase tracing sample rate for better coverage
          tracesSampleRate: 1.0,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
          // Enable performance monitoring
          enablePerformanceMonitoring: true,
          profilesSampleRate: 1.0,

          integrations: [
            Sentry.browserTracingIntegration({
              idleTimeout: 5000,
            }),
            Sentry.reactRouterV5BrowserTracingIntegration({ history }),
            Sentry.replayIntegration({
              maskAllText: false,
              maskAllInputs: false,
              blockAllMedia: false,
            }),
            Sentry.browserProfilingIntegration(),
          ],
        });
      }
    },

    captureException: (error, extra) => {
      if (error && installSentry) {
        Sentry.withScope((scope) => {
          scope.setExtras(extra);
          Sentry.captureException(error);
        });
      }
    },

    setUser: (user) => {
      if (user && installSentry) {
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
      }
    },

    wrapRoute: (route) => {
      if (installSentry) {
        return Sentry.withSentryRouting(route);
      }
      return route;
    },
  };
};

// Export the sentry object with all methods
export const sentry = setupSentry();
