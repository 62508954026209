import amazonCA from 'app/assets/images/icons/amazon-ca-icon.svg';
import amazonDE from 'app/assets/images/icons/amazon-de-icon.svg';
import amazonES from 'app/assets/images/icons/amazon-es-icon.svg';
import amazonFR from 'app/assets/images/icons/amazon-fr-icon.svg';
import amazonGB from 'app/assets/images/icons/amazon-gb-icon.svg';
import amazonIT from 'app/assets/images/icons/amazon-it-icon.svg';
import amazonMX from 'app/assets/images/icons/amazon-mx-icon.svg';
import amazonNone from 'app/assets/images/icons/amazon-none-icon.svg';
import amazonUK from 'app/assets/images/icons/amazon-uk-icon.svg';
import amazonUS from 'app/assets/images/icons/amazon-us-icon.svg';

export type MarketplaceFilter = {
  label: string;
  marketplace_id: Marketplace;
};

export enum Marketplace {
  US = 'US',
  CA = 'CA',
  GB = 'GB',
  MX = 'MX',
  UK = 'UK',
  DE = 'DE',
  IT = 'IT',
  ES = 'ES',
  FR = 'FR',
}

export const marketProperty = {
  [Marketplace.US]: {
    flag: amazonUS,
    label: 'amazon.com',
    host: 'amazon.com',
    currency: '$',
  },
  [Marketplace.CA]: {
    flag: amazonCA,
    label: 'amazon.ca',
    host: 'amazon.ca',
    currency: '$',
  },
  [Marketplace.GB]: {
    flag: amazonGB,
    label: 'amazon.gb',
    host: 'amazon.gb',
    currency: '$',
  },
  [Marketplace.MX]: {
    flag: amazonMX,
    label: 'amazon.com.mx',
    host: 'amazon.com.mx',
    currency: '$',
  },
  [Marketplace.UK]: {
    flag: amazonUK,
    label: 'amazon.co.uk',
    host: 'amazon.co.uk',
    currency: '£',
    hasNew: ['projects'],
    hasBeta: ['keyword-spy'],
  },
  [Marketplace.DE]: {
    flag: amazonDE,
    label: 'amazon.de',
    host: 'amazon.de',
    currency: '€',
    hasBeta: ['projects', 'keyword-spy'],
    hasNew: ['asin-insight'],
    disabledPages: [],
  },
  [Marketplace.IT]: {
    flag: amazonIT,
    label: 'amazon.it',
    host: 'amazon.it',
    currency: '€',
    hasBeta: ['projects'],
    disabledPages: ['projects'],
  },
  [Marketplace.ES]: {
    flag: amazonES,
    label: 'amazon.es',
    host: 'amazon.es',
    currency: '€',
    hasBeta: ['projects'],
    disabledPages: ['projects'],
  },
  [Marketplace.FR]: {
    flag: amazonFR,
    label: 'amazon.fr',
    host: 'amazon.fr',
    currency: '€',
    hasBeta: ['projects'],
    disabledPages: ['projects'],
  },
};

export const getSupportedMarketplaces = (
  isAccessAllMarketplaceFeatureEnabled: boolean | undefined,
  pageType: string,
) => {
  if (isAccessAllMarketplaceFeatureEnabled)
    return Object.keys(marketProperty).filter(
      (marketplace) => marketplace !== Marketplace.GB,
    );

  const results = [Marketplace.US, Marketplace.CA, Marketplace.MX];

  if (pageType === 'keyword-spy' || pageType === 'projects') {
    results.push(Marketplace.UK, Marketplace.DE);
  }

  if (pageType === 'asin-insight') {
    results.push(Marketplace.UK, Marketplace.DE);
  }

  return results;
};

export const getFlag = (id: Marketplace | string) => {
  return marketProperty[id]?.flag || amazonNone;
};

export const getAmazonLabel = (id: Marketplace) => {
  return marketProperty[id]?.label || marketProperty[Marketplace.US].label;
};

export const getAmazonHost = (id: Marketplace) => {
  return marketProperty[id]?.host || marketProperty[Marketplace.US].host;
};

export const optionsMarketplace = Object.keys(marketProperty)
  .filter((marketplace) => marketplace !== Marketplace.GB)
  .map((marketplace) => {
    const value = marketProperty[marketplace];
    return {
      label: value.label,
      marketplace_id: marketplace,
      hasNew: value.hasNew,
      hasBeta: value.hasBeta,
      disabledPages: value.disabledPages,
    };
  });

export const getCurrencySymbol = (marketplace_id: Marketplace) => {
  return (
    marketProperty[marketplace_id]?.currency ||
    marketProperty[Marketplace.US].currency
  );
};

export const sortListProjectMarketplace = (
  projects: any[],
  projectId: string,
) => {
  const sortListProjectMarketplace: any[] = [];
  const findProject = projects?.find(
    (project) => project.project_id === projectId,
  );
  if (findProject?.link_id) {
    const listProjectMarketplace =
      projects?.filter((item) => item.link_id === findProject?.link_id) || [];

    optionsMarketplace.forEach((item) => {
      const pr = listProjectMarketplace.find(
        (i) => i.marketplace === item.marketplace_id,
      );
      pr && sortListProjectMarketplace.push(pr);
    });
  }
  return sortListProjectMarketplace;
};

export const getProjectWithMarketPlace = (list: any[], marketplace: string) => {
  return list.find((item) => item.marketplace === marketplace);
};

export const getProjectSelected = (project: any) => {
  let projectSelected = project;
  if (project.list_marketplace) {
    for (let i = 0; i < optionsMarketplace.length; i++) {
      const pr = getProjectWithMarketPlace(
        project.list_marketplace,
        optionsMarketplace[i].marketplace_id,
      );
      if (pr) {
        projectSelected = pr;
        break;
      }
    }
  }
  return projectSelected;
};

export const getAsinAmazonURL = (marketplace: Marketplace, asin: string) => {
  return `https://www.${getAmazonLabel(marketplace)}/dp/${asin}`;
};
