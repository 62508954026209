import React from 'react';
import { toast } from 'react-toastify';
import './styles.scss';

import SuccessIcon from 'app/assets/images/toast/success-icon.svg';
import ErrorIcon from 'app/assets/images/toast/error-icon.svg';
import InfoIcon from 'app/assets/images/toast/info-icon.svg';
import WarningIcon from 'app/assets/images/toast/warning-icon.svg';

const defaultErrorMessage = 'Something went wrong';
const defaultSuccessMessage = 'Success';
const defaultStyles = {
  background: '#FFFFFF',
  boxShadow: 'unset',
  filter:
    'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16)) drop-shadow(0px 6px 12px rgba(123, 124, 150, 0.24))',
  color: 'rgba(255,255,255,0.8)',
  borderRadius: '8px',
  fontSize: 14,
  padding: '0',
  '& .Toastify__toastBody': {
    padding: '0',
  },
  minWidth: '495px',
};

const defaultOptions = {
  hideProgressBar: true,
  position: 'bottom-right',
  autoClose: 5000,
};

export const customToast = (text, icon, type) => {
  return (
    <div className="toast-container">
      <div className={`${type} image`}>
        <img src={icon} alt="" />
      </div>
      <div className="toast-content">
        <div className="toast-title">{type}</div>
        <div className="toast-summary">{text}</div>
      </div>
    </div>
  );
};

export const notify = {
  ...toast,
  error(errorMessage = defaultErrorMessage, options) {
    return toast(customToast(errorMessage, ErrorIcon, 'error'), {
      ...defaultOptions,
      style: {
        ...defaultStyles,
        ...options?.style,
      },
    });
  },
  success(successMessage = defaultSuccessMessage, options) {
    return toast(customToast(successMessage, SuccessIcon, 'success'), {
      ...defaultOptions,
      style: {
        ...defaultStyles,
        ...options?.style,
      },
    });
  },
  warning(warningMessage = defaultSuccessMessage, options) {
    return toast(customToast(warningMessage, WarningIcon, 'warning'), {
      ...defaultOptions,
      style: {
        ...defaultStyles,
        ...options?.style,
      },
    });
  },
  info(infoMessage = defaultSuccessMessage, options) {
    return toast(customToast(infoMessage, InfoIcon, 'info'), {
      ...defaultOptions,
      style: {
        ...defaultStyles,
        ...options?.style,
      },
    });
  },
};
