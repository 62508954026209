/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from 'react';
import ReactDOM from 'react-dom';
// import axios from "axios";
import { loadStripe } from '@stripe/stripe-js';
import Amplify from 'aws-amplify';
import config from 'config';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import App from './App';
import store, { persistor } from './app/store/store';
// import { mockAxios, setupAxios } from "./_metronic";
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
// IE 11 polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// Fonts
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'socicon/css/socicon.css';
import './_metronic/_assets/plugins/avenir/avenir.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';

import { injectFakeTime } from './app/utils/time';

import ErrorBoundary from './app/components/ErrorBoundary';
import { sentry } from './app/utils/sentry';

if (new Date().getTimezoneOffset() > 0) {
  injectFakeTime({ name: 'TEST', value: 0 });
}

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ mockAxios(axios);
/**
 *
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// setupAxios(axios, store);

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

const initStripe = async (retryCount = 0) => {
  try {
    const stripe = await loadStripe(`${config.stripe.PUBLIC_KEY}`);
    if (!stripe) {
      throw new Error('Failed to initialize Stripe');
    }
    window.stripe = stripe;
  } catch (error) {
    console.error(
      `Stripe initialization failed (attempt ${retryCount + 1}/3):`,
      error,
    );

    if (retryCount < 2) {
      console.log(`Retrying Stripe initialization in 2 seconds...`);
      setTimeout(() => {
        initStripe(retryCount + 1);
      }, 2000);
    } else {
      console.error('Stripe initialization failed after 3 attempts');
      sentry.captureException(error);
    }
  }
};

initStripe();

sentry.init();

const POSTHOG_API_KEY = config.posthog.API_KEY;

posthog.init(POSTHOG_API_KEY, {
  api_host: 'https://us.i.posthog.com',
});

if (window && window.location.hostname.includes('localhost')) {
  posthog.debug();
}

ReactDOM.render(
  <ErrorBoundary>
    <PostHogProvider client={posthog}>
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </PostHogProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
