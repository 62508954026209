import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, ButtonTypes } from 'app/components/Material/Inputs/Button';
import { BetaTag } from '../Common/BetaTag';
import clsx from 'clsx';
import {
  useIsTeamMenuFeatureEnabled,
  useIsSettingConnectionMenuFeatureEnabled,
} from 'app/utils/posthog';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 24px',
    color: '#222',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px' /* 142.857% */,
  },
  subMenu: {
    padding: '16px 0',
  },
  subName: {
    color: '#999',
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '16px' /* 133.333% */,
    letterSpacing: '0.12px',
    textTransform: 'uppercase',
    padding: '14px 24px',
  },
  menuSubItem: {
    padding: '14px 24px',
    cursor: 'pointer',
    color: '#222',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    position: 'relative',
    '&:hover': {
      background: 'var(--hover, rgba(86, 94, 237, 0.08))',
    },
  },
  isActive: {
    background: 'rgba(46, 49, 94, 0.08)',
  },
}));

export const MenuSettings = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { handleCloseSettings, lastPath } = props;

  const isSettingConnectionMenuFeatureEnabled =
    useIsSettingConnectionMenuFeatureEnabled();

  const isTeamMenuFeatureEnabled = useIsTeamMenuFeatureEnabled();

  const onClose = () => {
    handleCloseSettings();
    if (lastPath) {
      if (lastPath.includes('/settings')) {
        history.push('/keyword-spy');
      } else {
        history.push(lastPath);
      }
    } else {
      history.push('/keyword-spy');
    }
  };

  let companyMenu = [
    {
      label: 'Company Profile',
      page: '/settings/company-profile',
    },
    {
      label: 'Billing',
      page: '/settings/billing',
    },
  ];

  isTeamMenuFeatureEnabled &&
    companyMenu.push({
      label: 'Team',
      page: '/settings/team',
    });

  isSettingConnectionMenuFeatureEnabled &&
    companyMenu.push({
      label: 'Connections',
      page: '/settings/connections',
      betaUser: true,
    });

  const userMenu = [
    {
      label: 'User Profile',
      page: '/settings/user-profile',
    },
  ];

  return (
    <div>
      <div className={classes.menuItem}>
        <span>Settings</span>
        <Button
          type={ButtonTypes.icon}
          icon="close"
          //   className={classes.close}
          onClick={onClose}
        />
      </div>
      <div className={classes.subMenu}>
        <div className={classes.subName}>COMPANY</div>
        {companyMenu.map((item, index) => (
          <div
            key={`company-menu-${index}`}
            onClick={() => history.push(item.page)}
            className={clsx(
              classes.menuSubItem,
              item.page === location.pathname && classes.isActive,
            )}
          >
            {item.betaUser && <BetaTag title="Beta" />}
            <span>{item.label}</span>
          </div>
        ))}
      </div>
      <div className={classes.subMenu}>
        <div className={classes.subName}>USER</div>
        {userMenu.map((item, index) => (
          <div
            key={`user-menu-${index}`}
            onClick={() => history.push(item.page)}
            className={clsx(
              classes.menuSubItem,
              item.page === location.pathname && classes.isActive,
            )}
          >
            <span>{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
